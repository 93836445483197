import React, { useEffect, useState } from 'react';
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Root from "./routes/root";
import ErrorPage from "./error-page";
import RegisterParcels from './routes/registerParcels';
import CollectionDetails from './routes/collectionDetails';
import ConfirmCollection from './routes/confirmCollection';
import Documents from './routes/documents';
import { Layout,FloatButton,Modal } from 'antd';
import Header from './components/header.js'
import Footer from './components/footer.js';
import { useNavigate, Outlet } from "react-router-dom";
import RegisterPackages from './routes/registerPackage';
import { QuestionCircleOutlined } from '@ant-design/icons';
const { Content} = Layout

const HelpModal = (props) => {
  return (
      <Modal title="Need Help?" open={props.isModalOpen} onOk={props.handleOk} onCancel={props.handleCancel}>
        <p>If you need some help, please head over to our website <a href='https://samplelogistics.com/sampleship'>here</a>, click the Request SampleShip Assistance button, fill in the requested details and a member of our team will be in contact with you to assist.</p>
        <p>Alternatively, you can send us an email to <a href='mailto:contact@samplelogistics.com'>contact@samplelogistics.com</a> quoting your parcel number.</p>
    </Modal>
  )
}

const PrivateRoute = ({ element: Element, parcel, ...rest }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(false)
  const [load, setLoad] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const contentStyle = {
    minHeight: "100%",
    lineHeight: '60px',
    color: '#fff',
    height: "calc(100vh - 24vh)"
  };
  
  
  useEffect(() => {
    const fetchData = async () => {
      if (sessionStorage.getItem('noParcel')){
        const data = await fetch(`${process.env.REACT_APP_API_URL}/register-packages?vessel=${sessionStorage.getItem('vesselId')}&no_parcel=true&system=hyundai.sampleship.com`);
        const json = await data.json();
        setAuth(json.vessel.authenticated)
        if (!json.vessel.authenticated){
          navigate("/", { replace: true });
          setLoad(false)
        }
        setLoad(false)
      } else if (sessionStorage.getItem('parcelNumber') !== null){
        const data = await fetch(`${process.env.REACT_APP_API_URL}/register-parcels?parcel=${sessionStorage.getItem('parcelNumber')}&system=hyundai.sampleship.com`);
        const json = await data.json();
        setAuth(json.parcel.authenticated)
        if (!json.parcel.authenticated){
          console.log("TRUTRU")
          navigate("/", { replace: true });
          setLoad(false)
        }
        setLoad(false)
      } else {
        console.log("TRUTRU2")
        navigate("/", { replace: true });
        setAuth(false)
      }
    }
    fetchData()
    
  }, [navigate]);

  if (window.location.pathname === "/"){
     navigate('/welcome')
  } else {
    if (load){
      return (
        <Layout>
        <Header />
        <Layout style={contentStyle} hasSider >   
            <Content/>  
        </Layout>
        <Footer />
        
    </Layout>
      )
    } else {
      return  auth ? <><Outlet /><HelpModal isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} /><FloatButton onClick={showModal} icon={<QuestionCircleOutlined />} type="primary" style={{ right: 40, bottom:80 }} /> </> : <></>;
    }
   
  }

 
};

// handle register parcel from index and pass props to register parcels component



const router = createBrowserRouter([
  {
    path: "/welcome/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/register-parcels/",
    element: <RegisterParcels />,
    errorElement: <ErrorPage />
  },
  {
    path: "/register-packages/",
    element: <RegisterPackages />,
    errorElement: <ErrorPage />
  },
  {
    path: "/",
    element: <PrivateRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "collection-details/",
        element: <CollectionDetails />,
        errorElement: <ErrorPage />
      },
      {
        path: "confirm-collection/",
        element: <ConfirmCollection />,
        errorElement: <ErrorPage />
      },
      {
        path: "documents/",
        element: <Documents />,
        errorElement: <ErrorPage />
        }
    ]
  },
  
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
